* {
    padding: 0;
    margin: 0;
    color: #fff;
    font-family: 'Roboto', sans-serif;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
    display: none;
}

::-webkit-scrollbar {
    /* width: 0px; */
    display: none;
}
  
/* Hide scrollbar for IE, Edge and Firefox */
body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    /* overflow: auto; */
    /* height: 100%; */
}

/* to hide errors from showing on the page */
iframe {
    display: none;
}

.truncate {
    overflow: hidden;
    display: -webkit-box;
    display: -moz-box;
    -webkit-line-clamp: 2;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
}